import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {ISessionSummaryController} from './SessionSummary.contracts';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';
import {ModalContent} from './SessionSummary.content';

import './SessionSummary.style.scss';

interface TsSessionSummaryProps {
    controller: ISessionSummaryController;

    onSummaryCompleted(): void;
}

@observer
export class SessionSummary extends TechseeBaseComponent<TsSessionSummaryProps> {
    private async onFinishClick() {
        try {
            await this.props.controller.saveSessionData(true);

            this.props.controller.hideSessionSummary();
            this.props.onSummaryCompleted();
        } catch {
            this.props.controller.OpenFailureDecisionModal();
        }
    }

    private onBackClick() {
        return this.props.controller.saveSessionData(false).finally(() => this.props.controller.hideSessionSummary());
    }

    private onFailureDecisionAbortClick() {
        this.props.controller.setFailureDecision(false);

        this.props.controller.hideSessionSummary();
        this.props.onSummaryCompleted();
    }

    private onFailureDecisionWaitClick() {
        this.props.controller.setFailureDecision(true);
    }

    renderInternal(): JSX.Element {
        const Header = () => <DefaultModalHeader>Session Summary</DefaultModalHeader>;

        const ToolBar = () => (
            <ButtonsToolbar>
                <SecondaryButton onClick={() => this.onBackClick()} className={'go-back'}>
                    {this.props.controller.translate('REACT.DASHBOARD.VIEW.GO_BACK')}
                </SecondaryButton>
                <PrimaryButton onClick={() => this.onFinishClick()} className={'save-end-session'}>
                    {this.props.controller.translate('REACT.DASHBOARD.VIEW.SAVE_AND_END_SESSION')}
                </PrimaryButton>
            </ButtonsToolbar>
        );

        return (
            <>
                <GenericModal
                    className={'session-summary-component'}
                    header={Header}
                    content={() => <ModalContent controller={this.props.controller} />}
                    toolbar={ToolBar}
                    onHideHandler={() => this.props.controller.hideSessionSummary()}
                    show={this.props.controller.isSummaryVisible}></GenericModal>
                <ConfirmationModal
                    contentTitle={this.props.controller.translate(
                        'REACT.DASHBOARD.VIEW.SESSION_SUMMARY.FAILURE_DECISION_MODAL.TITLE'
                    )}
                    content={this.props.controller.translate(
                        'REACT.DASHBOARD.VIEW.SESSION_SUMMARY.FAILURE_DECISION_MODAL.DESCRIPTION'
                    )}
                    negativeText={this.props.controller.translate('REACT.COMMON.BUTTONS.CANCEL')}
                    positiveText={this.props.controller.translate('REACT.COMMON.BUTTONS.DECLINE')}
                    show={this.props.controller.isFailureDecisionOpen}
                    onNegativeClick={() => this.onFailureDecisionWaitClick()}
                    onPositiveClick={() => this.onFailureDecisionAbortClick()}></ConfirmationModal>
            </>
        );
    }
}
